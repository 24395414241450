<template>
<div>
  {{ movies }}
</div>
</template>

<script>
import {defineComponent} from "vue";
import MoviesQuery from "../../queries/movies/MoviesQuery";

export default defineComponent({
  apollo: {
    movies: {
      query: MoviesQuery
    }
  }
})
</script>

<style scoped>

</style>
