import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute h-6 bg-gray-800 text-white text-sm inset-x-0 bottom-0 bg-opacity-60 items-center px-3 flex justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_icon = _resolveComponent("b-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(_ctx.performer.age), 1),
    _createElementVNode("span", null, [
      (_ctx.performer.nationality)
        ? (_openBlock(), _createBlock(_component_b_icon, {
            key: 0,
            icon: _ctx.performer.flagIcon,
            inline: true
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("span", null, [
      _createVNode(_component_b_icon, {
        icon: _ctx.performer.genderIcon
      }, null, 8, ["icon"])
    ])
  ]))
}