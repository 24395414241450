import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "group flex overflow-hidden flex-col bg-white rounded shadow-lg" }
const _hoisted_2 = { class: "overflow-hidden relative" }
const _hoisted_3 = { class: "flex items-center h-10 absolute inset-x-0 bottom-0 justify-between py-1 px-3 text-sm text-white bg-gray-800 bg-opacity-60" }
const _hoisted_4 = { class: "w-1/2" }
const _hoisted_5 = ["title"]
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "flex flex-col flex-grow" }
const _hoisted_8 = { class: "flex-grow" }
const _hoisted_9 = { class: "p-3 text-xl truncate" }
const _hoisted_10 = { class: "flex flex-wrap p-3 border-t border-gray-300 h-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_image = _resolveComponent("b-image")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_performer_badge = _resolveComponent("performer-badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: `/scenes/${ _ctx.scene.slug }`
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_image, {
            src: _ctx._scene.background,
            class: "object-cover w-full h-64 transition duration-300 ease-in-out transform group-hover:scale-105",
            placeholder: _ctx._scene.placeholder,
            preset: "backgroundSmall",
            alt: _ctx.scene.title
          }, null, 8, ["src", "placeholder", "alt"])
        ]),
        _: 1
      }, 8, ["to"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx._scene.dateFormatted), 1),
        _createElementVNode("div", {
          class: "flex items-center justify-end space-x-2 w-1/2",
          title: _ctx.scene.site.name
        }, [
          (_ctx.scene.site.favicon)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.scene.site.favicon.url,
                alt: _ctx.scene.site.name,
                loading: "lazy",
                class: "h-4"
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true),
          _createVNode(_component_router_link, {
            to: `${_ctx.scene.site.slug}`,
            class: "truncate"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.scene.site.name), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ], 8, _hoisted_5)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("h2", _hoisted_9, [
          _createVNode(_component_router_link, {
            to: `/scenes/${ _ctx.scene.slug }`,
            title: _ctx.scene.title
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.scene.title), 1)
            ]),
            _: 1
          }, 8, ["to", "title"])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx._scene.limitedPerformers, (performer) => {
          return (_openBlock(), _createBlock(_component_performer_badge, {
            key: `performer-${performer.id}`,
            "performer-site": performer
          }, null, 8, ["performer-site"]))
        }), 128))
      ])
    ])
  ]))
}