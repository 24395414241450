import {App, Plugin} from "vue";
import {NButton, NSpin, NSelect, NButtonGroup, NPagination, NCarousel, NDataTable, NCard, NInput, NAutoComplete} from 'naive-ui'

export default {
  install(Vue: App) {
    Vue.component('NButton', NButton)
    Vue.component('NBtn', NButton)
    Vue.component('NSpin', NSpin)
    Vue.component('NSelect', NSelect)
    Vue.component('NPagination', NPagination)
    Vue.component('NCarousel', NCarousel)
    Vue.component('NDataTable', NDataTable)
    Vue.component('NButtonGroup', NButtonGroup)
    Vue.component('NCard', NCard)
    Vue.component('NInput', NInput)
    Vue.component('NAutoComplete', NAutoComplete)
  }
} as Plugin
