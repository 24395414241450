
import {defineComponent} from "vue";
import ScenesQuery from "@/queries/scene/ScenesQuery";
import {QueryScenesArgs, SceneConnection} from "@/types/graphql";
import {IResourceMeta} from "@/types/pagination";
import SceneCard from "@/components/scenes/SceneCard.vue";
import BPagination from "@/components/base/BPagination.vue";
import BPopTransition from "@/components/base/BPopTransition.vue";
import BOverlay from "@/components/base/BOverlay.vue";

export default defineComponent({
  name: "ScenesIndex",
  components: {BOverlay, BPopTransition, BPagination, SceneCard},
  apollo: {
    scenes: {
      query: ScenesQuery,
      result(): void {
        this.$router.push({
          path: this.$route.path,
          query: {
            page: this.page,
            sortBy: this.sortBy
          }
        })

        window.scrollTo(0, 0)
      },
      variables(): QueryScenesArgs {
        return {
          page: this.page,
          itemsPerPage: this.limit,
          order: [
            {
              [this.orderBy.column]: this.orderBy.desc ? 'DESC' : 'ASC'
            }
          ],
        }
      }
    }
  },
  data() {
    return {
      sortBy: this.$route.query.sortBy || 'recently_created',
      query: this.$route.query.q || null,
      page: parseInt(this.$route.query.page as string) || 1,
      limit: 25,
      scenes: null as SceneConnection | null
    }
  },
  methods: {
    onPaginate(page: number): void {
      console.log(page)
      this.page = page
    }
  },
  computed: {
    paginationMeta(): IResourceMeta {
      return {
        current_page: this.page,
        per_page: this.limit,
        total: this.scenes?.paginationInfo?.totalCount ?? 0
      }
    },
    orderOptions(){
      return [
        {label: 'Recently Added', value: 'recently_created'},
        {label: 'Oldest Added', value: 'former_created'},
        {label: 'Recently Added', value: 'recently_updated'},
        {label: 'Oldest Updated', value: 'former_updated'},
        {label: 'Newest Released', value: 'recently_released'},
        {label: 'Oldest Released', value: 'former_released'},
      ]
    },

    orderBy() {
      switch (this.sortBy) {
        case 'former_created':
          return {
            column: 'created_at',
            desc: false
          };
        case 'recently_updated':
          return {
            column: 'updated_at',
            desc: true
          };
        case 'former_updated':
          return {
            column: 'updated_at',
            desc: false
          };
        case 'recently_released':
          return {
            column: 'date',
            desc: true
          };
        case 'former_released':
          return {
            column: 'date',
            desc: false
          };
        case 'recently_created':
        default:
          return {
            column: 'created_at',
            desc: true
          };
      }
    }
  },
})
