
import {defineComponent} from 'vue'
import ConsentOverlay from '@/components/base/ConsentOverlay.vue'
import BNavLink from '@/components/base/BNavLink.vue'
import BIcon from '@/components/base/BIcon.vue'
import BNavMenu from '@/components/base/BNavMenu.vue'

interface IRoute {
  text: string
  url: string
}

import {useSettingsStore} from "@/store/settings";

export default defineComponent({
  name: 'DefaultLayout',
  components: {BNavMenu, BIcon, ConsentOverlay, BNavLink},
  data() {
    return {
      showMobileMenu: false,
      routes: [
        {text: 'Home', url: '/'},
        {text: 'Scenes', url: '/scenes'},
        {text: 'Performers', url: '/performers'},
        {text: 'Sites', url: '/sites'},
        {text: 'Networks', url: '/networks'}
      ] as IRoute[]
    }
  },
  setup () {
    const settingStore = useSettingsStore()

    return {
      settingStore
    }
  },
  computed: {
    avatar(): string {
      // if (this.$auth.loggedIn) {
      //   return `https://avatars.dicebear.com/api/pixel-art/${this.$auth.user?.username}.svg`
      // }

      return ''
    },
    // sfwMode: {
    //   get (): boolean {
    //     // return this.$store.state.settings.sfwMode
    //     return true
    //   },
    //   set (value: boolean): void {
    //     // this.$store.commit('settings/setSfwMode', value)
    //     // this.$storage.setUniversal('sfwMode', value)
    //   }
    // },
    user() {
      return {}
    },
    title() {
      // return this.$store.state.page.title || 'Change Me'
      return 'Change Me'
    },
    showConsentModal() {
      return false
      // return this.$store.state.page.showConsentModal
    },
    loggedIn() {
      return false
    }
  },

  mounted(): void {
    // this.sfwMode = this.$storage.getUniversal('sfwMode') ?? false
    // const hasConsented = this.$storage.getUniversal('hasConsented') ?? false
    // this.$store.commit('page/setConsentModal', !hasConsented)
  },
  methods: {
    login() {
      //
    },
    logout() {
      //
    }
  }
})

