<template>
    <div :class="mobile ? 'nav-menu-mobile' : 'nav-menu'">
      <div ref="target" @click="open = !open">
        <slot v-if="!label" name="button"></slot>
        <b-nav-link v-else>
          {{ label }}
          <b-icon icon="fa-solid:chevron-down" class="inline ml-1"></b-icon>
        </b-nav-link>
      </div>

      <transition
        leave-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
        leave-active-class="transition ease-in duration-75"
        enter-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        enter-active-class="transition ease-out duration-100">
        <div
          v-show="open"
          class="nav-menu-dropdown"
          role="menu" tabindex="-1">
          <slot></slot>
        </div>
      </transition>
    </div>
</template>

<script>
import {defineComponent, ref} from 'vue'
import BIcon from '@/components/base/BIcon.vue'
import BNavLink from '@/components/base/BNavLink.vue'
import { onClickOutside } from '@vueuse/core'
export default defineComponent({
  name: 'BNavMenu',
  components: { BIcon, BNavLink },
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  setup (){
    const target = ref(null)
    const open = ref(false)

    const close = () => {
      open.value = false
    }

    onClickOutside(target, (e) => {
      console.log(e)
      open.value = false
    })

    return {
      open,
      close
    }
  },
  computed: {
    mobile () {
      // TODO
      return false
    }
  }
})
</script>

<style>
.nav-menu {
  @apply ml-3 relative;

  .nav-menu-dropdown {
    @apply z-20 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none;
  }
}

</style>
