


import {defineComponent} from "vue";
import {isNull, omitBy, toString} from "lodash";
import {IResourceMeta} from "@/types/pagination";
import {QuerySitesArgs, SiteConnection} from "@/types/graphql";
import SitesQuery from "@/queries/sites/SitesQuery";
import BOverlay from "@/components/base/BOverlay.vue";
import BPopTransition from "@/components/base/BPopTransition.vue";
import BPagination from "@/components/base/BPagination.vue";

export default defineComponent({
  components: {BPagination, BPopTransition, BOverlay},
  apollo: {
    sites: {
      query: SitesQuery,
      result() {
        this.$router.push({
          path: this.$route.path,
          query: omitBy({
            page: this.page,
            letter: this.selectedLetter
          }, isNull)
        })
      },
      variables(): QuerySitesArgs {
        return {
          itemsPerPage: 35,
          page: this.page,
          name: this.selectedLetter || undefined,
          order: [
            {
              [this.sort.column]: this.sort.order,
            }
          ]

        }
      }
    }
  },
  data() {
    return {
      sites: {
        collection: [],
        paginationInfo: {
          itemsPerPage: 35,
          totalCount: 0,
          lastPage: 0,
        }
      } as SiteConnection,
      selectedLetter: toString(this.$route.query?.letter as string) || null,
      query: '',
      page: parseInt(this.$route.query?.page as string ?? 1),
      limit: 24,
      letters: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      selectOptions: [
        {label: 'A-Z', value: {column: 'name', order: 'ASC'}},
        {label: 'Z-A', value: {column: 'name', order: 'DESC'}},
      ],
      sort: {
        column: toString(this.$route.query.sortBy) || 'name',
        order: toString(this.$route.query.order) || 'ASC'
      },
    }
  },
  computed: {
    paginationMeta(): IResourceMeta {
      return {
        current_page: this.page,
        per_page: this.limit,
        total: this.sites?.paginationInfo?.totalCount ?? 0
      }
    }
  },
  methods: {
    setLetter(letter: string): void {
      if (letter === this.selectedLetter) {
        this.selectedLetter = null
        return
      }

      this.selectedLetter = letter
    },
    onPaginate(page: number): void {
      this.page = page
    }
  }
});
