interface IWaterMark {
  url: string
}

interface IFilters {
  blur?: number
  format?: "webp" | "jpeg" | "gif" | "png"
  noUpscale?: boolean
  quality?: number
  sharpen?: number
  stretch?: boolean
  upscale?: boolean
  waterMark?: IWaterMark
}


interface ISettings {
  fitIn?: boolean
  fullFitIn?: boolean
  smart?: boolean
  filters: IFilters
  url?: string
  height?: number | null
  width?: number | null
}


export default class ImageProxy {

  private settings: ISettings

  constructor(settings: Partial<ISettings>) {
    this.settings = {
      url: settings.url ?? undefined,
      fitIn: settings.fitIn ?? undefined,
      fullFitIn: settings.fullFitIn ?? undefined,
      smart: settings.smart ?? true,
      height: settings.height ?? undefined,
      width: settings.width ?? undefined,
      filters: {
        blur: settings.filters?.blur ?? undefined,
        format: settings.filters?.format ?? undefined,
        noUpscale: settings.filters?.noUpscale ?? undefined,
        quality: settings.filters?.quality ?? undefined,
        sharpen: settings.filters?.sharpen ?? undefined,
        stretch: settings.filters?.stretch ?? undefined,
        upscale: settings.filters?.upscale ?? undefined,
        waterMark: settings.filters?.waterMark ?? undefined
      }
    }
  }

  getBackground(src: string, width = 3000, height?: number): string {

    if (!height) {
      height = width
    }

    return this.url(src)
      .smart(true)
      .fitIn(width, height)
      .format('jpeg')
      .quality(80)
      .sharpen(1)
      .upscale(true)
      .get()
  }

  getBackgroundSmall(src: string): string {
    return this.getBackground(src, 500, 500)
  }

  getBackgroundMedium(src: string): string {
    return this.getBackground(src, 1500, 1500)
  }

  getPoster(src: string, width = 1500, height?: number): string {
    if (!height) {
      height = width * 1.5
    }

    return this.url(src)
      .smart(true)
      .resize(width, height)
      .format('jpeg')
      .quality(80)
      .sharpen(1)
      .upscale(true)
      .get()
  }

  getPosterSmall(src: string): string {
    return this.getPoster(src, 500)
  }

  getPosterMedium(src: string): string {
    return this.getPoster(src, 1000)
  }

  url(val: ISettings['url']): this {
    this.settings.url = val

    return this
  }

  fitIn(width: ISettings['width'], height: ISettings['height'], smart = true): this {
    this.settings.fitIn = true
    this.settings.fullFitIn = false
    this.settings.height = height
    this.settings.width = width
    this.settings.smart = smart

    return this
  }

  fullFitIn(width: ISettings['width'], height: ISettings['height'], smart = true): this {
    this.settings.fullFitIn = true
    this.settings.fitIn = false
    this.settings.height = height
    this.settings.width = width
    this.settings.smart = smart

    return this
  }

  resize(width: ISettings['width'], height: ISettings['height'], smart = true): this {
    this.settings.fullFitIn = false
    this.settings.fitIn = false
    this.settings.height = height
    this.settings.width = width
    this.settings.smart = smart

    return this
  }

  smart(val: ISettings['smart']): this {
    this.settings.smart = val

    return this
  }

  blur(val: IFilters['blur']): this {
    this.settings.filters.blur = val

    return this
  }

  format(val: IFilters['format']): this {
    this.settings.filters.format = val

    return this
  }

  noUpscale(val: IFilters['noUpscale']): this {
    this.settings.filters.noUpscale = val

    return this
  }

  quality(val: IFilters['quality']): this {
    this.settings.filters.quality = val

    return this
  }

  sharpen(val: IFilters['sharpen']): this {
    this.settings.filters.sharpen = val

    return this
  }

  stretch(val: IFilters['stretch']): this {
    this.settings.filters.stretch = val

    return this
  }

  upscale(val: IFilters['upscale']): this {
    this.settings.filters.upscale = val

    return this
  }

  get(): string {

    const settings = [
      'https://thumb2.metadataapi.net',
      'unsafe',
    ];

    const filters = [];

    if (!this.settings.url) {
      throw new Error('Must include image')
    }

    if (this.settings.fitIn) {
      settings.push('fit-in')
    }

    if (this.settings.fullFitIn) {
      settings.push('full-fit-in')
    }

    settings.push(
      `${this.settings.width || 0}x${this.settings.height || 0}`
    )

    if (this.settings.smart) {
      settings.push('smart')
    }

    if (this.settings.filters.blur) {
      filters.push(`blur(${this.settings.filters.blur})`)
    }

    if (this.settings.filters.quality) {
      filters.push(`quality(${this.settings.filters.quality})`)
    }

    if (this.settings.filters.format) {
      filters.push(`format(${this.settings.filters.format})`)
    }

    if (this.settings.filters.noUpscale) {
      filters.push(`no_upscale()`)
    }

    if (this.settings.filters.sharpen) {
      filters.push(`sharpen(${this.settings.filters.sharpen})`)
    }

    if (this.settings.filters.stretch) {
      filters.push(`stretch()`)
    }

    if (this.settings.filters.upscale) {
      filters.push(`upscale()`)
    }

    settings.push(
      filters.map((f) => `filters:${f}`).join(',')
    )

    settings.push(
      encodeURIComponent(this.settings.url)
    )

    return settings.join('/')
  }

}
