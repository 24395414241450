import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.to)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: _ctx.href ? _ctx.href : '#',
          target: _ctx.href ? '_blank' : null,
          class: _normalizeClass(_ctx.mobile ? 'nav-link-mobile' : 'nav-link'),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click', $event)))
        }, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ])
        ], 10, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.to)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: _ctx.to,
          class: _normalizeClass(_ctx.mobile ? 'nav-link-mobile' : 'nav-link'),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click', $event))),
          exact: _ctx.to === '/',
          "active-class": "nav-link-active"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ])
          ]),
          _: 3
        }, 8, ["to", "class", "exact"]))
      : _createCommentVNode("", true)
  ], 64))
}