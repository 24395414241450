import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Home from '@/pages/home.vue'
import SceneIndex from '@/pages/scenes/index.vue'
import SceneShow from '@/pages/scenes/show.vue'
import PerformerShow from '@/pages/performers/show.vue'
import PerformerIndex from '@/pages/performers/index.vue'
import SiteShow from '@/pages/sites/show.vue'
import SiteIndex from '@/pages/sites/index.vue'
import NetworkShow from '@/pages/networks/show.vue'
import NetworkIndex from '@/pages/networks/index.vue'
import NotFound from '@/pages/notFound.vue'
import MoviesIndex from '@/pages/movies/index.vue'
import DefaultLayout from '@/layouts/default.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Default',
    component: DefaultLayout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/scenes',
        name: 'ScenesIndex',
        component: SceneIndex
      },
      {
        path: '/scenes/:slug',
        name: 'ScenesShow',
        component: SceneShow
      },
      {
        path: '/performers/:slug',
        name: 'PerformerShow',
        component: PerformerShow,
        props: true
      },
      {
        path: '/performers',
        name: 'PerformersIndex',
        component: PerformerIndex
      },

      {
        path: '/sites/:slug',
        name: 'SiteShow',
        component: SiteShow,
        props: true
      },
      {
        path: '/sites',
        name: 'SitesIndex',
        component: SiteIndex
      },

      {
        path: '/networks/:slug',
        name: 'NetworkShow',
        component: NetworkShow,
        props: true
      },
      {
        path: '/networks',
        name: 'NetworksIndex',
        component: NetworkIndex
      },
      {
        path: '/movies',
        name: 'MoviesIndex',
        component: MoviesIndex
      }
    ]
  },



  {path: '/404', component: NotFound},
  // {path: '*', redirect: '/404'},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
