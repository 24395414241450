import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "group" }
const _hoisted_2 = { class: "flex p-2 bg-gray-100 group-hover:bg-gray-200" }
const _hoisted_3 = { class: "px-3 font-bold text-white bg-gray-600 group-hover:bg-pink-400 rounded" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "flex-grow text-right select-all" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.performer.network)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: `/networks/${_ctx.performer.network.short_name}`
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                (_ctx.performer.network.favicon)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.performer.network.favicon.url,
                      class: "inline mr-2 h-4",
                      alt: ""
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.performer.network.name), 1)
              ])
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, {
          to: `/performer-networks/${_ctx.performer.uuid}`
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.performer.name), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ])
  ]))
}