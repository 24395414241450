
import {defineComponent} from "vue";
import PerformerBadge from "@/components/performers/PerformerBadge.vue";
import {useIntervalFn} from "@vueuse/core";
import SceneEntity from "@/entities/SceneEntity";
import BImage from "@/components/base/BImage.vue";
import {QuerySceneArgs, Scene} from "@/types/graphql";
import SceneQuery from "@/queries/scene/SceneQuery";

export default defineComponent({
  name: 'FeaturedScene',
  components: {BImage, PerformerBadge},
  apollo: {
    scene: {
      query: SceneQuery,
      variables(): QuerySceneArgs {
        return {
          id: '/scenes/' + this.scenes[this.currentIndex]
        }
      }
    }
  },
  setup() {
    let currentIndex = 0
    const scenes = ['378151', '928115', '933029', '933032', '927737', '931991']

    useIntervalFn(() => {
      const count = scenes.length - 1
      let index = currentIndex + 1


      if (index > count) {
        index = 0
      }

      currentIndex = index
    }, 5000)

    return {
      currentIndex,
      scenes
    }
  },
  computed: {
    _scene(): SceneEntity | undefined {
      if (this.scene) {
        return new SceneEntity(this.scene)
      }

      return undefined
    }
  },
  data() {
    return {
      scene: {} as Scene,
    }
  }
})
