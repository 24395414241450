import gql from "graphql-tag";

export default gql`
  query ScenesQuery($itemsPerPage: Int, $page: Int, $order: [SceneFilter_order], $performerSites_performer: String, $site: String) {
    scenes(itemsPerPage: $itemsPerPage, page: $page, order: $order, performerSites_performer: $performerSites_performer, site: $site) {
      paginationInfo {
        totalCount
      }
      collection {
        id
        slug
        title
        date
        media {
          uuid
          url
        }
        site {
          id
          name
          short_name
          favicon {
            url
          }
        }
        performerSites {
          collection {
            id
            name
            gender
            performer {
              id
              name
              slug
              gender
            }
          }
        }
      }
    }
  }
`
