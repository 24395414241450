import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5 p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_featured_scene = _resolveComponent("featured-scene")!
  const _component_scene_card = _resolveComponent("scene-card")!
  const _component_b_overlay = _resolveComponent("b-overlay")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_overlay, {
      loading: _ctx.$apollo.loading
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_featured_scene),
          (_ctx.scenes)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.scenes.collection, (scene) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: scene.id
                }, [
                  _createVNode(_component_scene_card, { scene: scene }, null, 8, ["scene"])
                ]))
              }), 128))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["loading"])
  ]))
}