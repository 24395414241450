import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client/core'
import {createApolloProvider} from '@vue/apollo-option'
import {App, Plugin} from 'vue'

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_GRAPHQL_URL,
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache: cache
})

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
})


const apollo: Plugin = {
  install: (Vue: App) => {
    Vue.use(apolloProvider)
  }
}

export default apollo
