import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", { key: _ctx.iconKey }, [
    _createVNode(_component_Icon, {
      icon: _ctx.actualIcon,
      class: _normalizeClass(_ctx.inline ? 'iconify-inline' : 'iconify'),
      "data-icon": _ctx.actualIcon
    }, null, 8, ["icon", "class", "data-icon"])
  ]))
}