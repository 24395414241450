import {DateTime} from "luxon";
import {App, Plugin} from "vue";

export const luxon = DateTime

export default {
  install(Vue: App) {
    Vue.config.globalProperties.$luxon = luxon
  }
} as Plugin
