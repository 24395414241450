<template>
  <n-spin :show="loading">
    <slot></slot>
  </n-spin>
</template>

<script>
export default {
  name: "BOverlay",
  props: {
    loading: {
      default: false,
      type: Boolean,
    }
  }
}
</script>

<style scoped>

</style>
