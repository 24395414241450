
import {defineComponent, h} from "vue";

export default defineComponent({
  name: "BPopTransition",
  props: {
    tag: {
      type: String,
      default: null
    }
  },
  render() {
    return h('transition-group', {
        props: {
          tag: this.tag,
          enterClass: 'transform opacity-0 scale-0',
          enterActiveClass: 'duration-300 delay-300',
          enterToClass: 'opacity-100 scale-100',
          mode: 'out-in',
          leaveClass: 'opacity-100 scale-100',
          leaveActiveClass: 'duration-300',
          leaveToClass: 'transform opacity-0 scale-0',
          appear: true
        },
      },
      [this.$slots.default ? this.$slots.default() : null]
    )
  }
})
