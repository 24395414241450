import gql from "graphql-tag";

export default gql`
  query PerformerSearch($itemsPerPage: Int, $page: Int, $order: [PerformerFilter_order], $letter: String) {
    performers(itemsPerPage: $itemsPerPage, page: $page, order: $order, name: $letter) {
      paginationInfo {
        totalCount
      }
      collection {
        id
        posters {
          uuid
          url
        }
        gender
        slug
        name
        nationality
        birthday
      }
    }
  }
`
