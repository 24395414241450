
import {defineComponent} from "vue";
import ImageProxy from "@/helpers/ImageProxy";

export default defineComponent({
  name: "BImage",
  props: {
    src: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    contain: {
      type: Boolean,
      default: false,
    },
    preset: {
      type: String,
      default: null
    }
  },
  computed: {
    imageClasses(): string[] {
      const classes = [];

      // if (this.$store.state.settings.sfwMode) {
        classes.push('filter')
        classes.push('blur-lg')
      // }

      if (['backgroundSmall'].includes(this.preset)) {
        classes.push('transform')
        classes.push('hover:scale-105')
      }

      return classes
    },
    imageUrl(): string {
      if (!this.preset) {
        return this.src
      }

      if (/thumb\.meta/.test(this.src)) {
        return this.src
      }

      const proxy = new ImageProxy({})


      switch (this.preset) {
        case 'posterSmall':
          return proxy.getPosterSmall(this.src);

        case 'posterMedium':
          return proxy.getPosterMedium(this.src);

        case 'posterLarge':
        case 'poster':
          return proxy.getPoster(this.src);

        case 'backgroundSmall':
          return proxy.getBackgroundSmall(this.src);

        case 'backgroundMedium':
          return proxy.getBackgroundMedium(this.src);

        case 'backgroundLarge':
        case 'background':
          return proxy.getBackground(this.src);
      }

      return this.src
    }
  }
})
