<template>
  <div class="absolute z-50 w-full h-full bg-gray-100">
    <div class="flex flex-col justify-center my-auto items-center h-full">

      <img src="/images/logo.png" class="w-48 h-auto mb-6" alt="">

      <div class="card bordered block">
        <div class="card-body bg-white">
          <form method="POST">
            <div class="text-center">
              <h1 class="uppercase text-xl font-semibold">Warning: adults only</h1>
              <p class="py-2 text-lg">Are you sure you want to enter?</p>
            </div>

            <div class="flex items-center justify-end mt-4">
              <button class="btn btn-primary btn-block" @click="closeModal">
                Enter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ConsentOverlay',
  methods: {
    closeModal () {
      // this.$storage.setUniversal('hasConsented', true)
      // this.$store.commit('page/setConsentModal', false)
    }
  }
})
</script>

<style scoped>

</style>
