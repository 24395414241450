


import ScenesQuery from "@/queries/scene/ScenesQuery";
import {QueryScenesArgs, SceneConnection} from "@/types/graphql";
import PerformerEntity from "@/entities/PerformerEntity";
import {IResourceMeta} from "@/types/pagination";
import {defineComponent, PropType} from "vue";
import SceneCard from "@/components/scenes/SceneCard.vue";
import BOverlay from "@/components/base/BOverlay.vue";
import BPagination from "@/components/base/BPagination.vue";

export default defineComponent({
  components: {BOverlay, BPagination, SceneCard},
  apollo: {
    scenes: {
      query: ScenesQuery,
      result(): void {
        this.$router.push({
          path: this.$route.path,
          query: {
            page: this.page
          }
        })
      },
      variables(): QueryScenesArgs {
        return {
          page: this.page,
          itemsPerPage: 8,
          performerSites_performer: this.performer.id.toString()
        }
      }
    }
  },
  props: {
    performer: {
      type: Object as PropType<PerformerEntity>,
      required: true
    }
  },
  data() {
    return {
      page: this.$route.query.page ? parseInt(this.$route.query.page as string) : 1,
      scenes: {
        collection: [],
        paginationInfo: {
          itemsPerPage: 8,
          lastPage: 0,
          totalCount: 0
        }
      } as SceneConnection
    }
  },
  computed: {
    paginationMeta(): IResourceMeta {
      return {
        current_page: this.page,
        per_page: 8,
        total: this.scenes.paginationInfo.totalCount
      }
    }
  },
  methods: {
    setPage(page: number): void {
      this.page = page
    }
  }
})
