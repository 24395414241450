
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    }
  }
})
