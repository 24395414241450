

import {defineComponent, resolveComponent} from 'vue'

export default defineComponent({
  name: 'BNavLink',
  props: {
    to: {
      type: String,
      required: false,
      default: ''
    },
    href: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    mobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    componentTag() {
      if (this.href) {
        return 'a'
      }
      return resolveComponent('router-link')
    }
  },
})
