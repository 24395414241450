import gql from "graphql-tag";

export default gql`
  query ($itemsPerPage: Int, $page: Int, $order: [SiteFilter_order], $letter: String, $network: String) {
    sites(itemsPerPage: $itemsPerPage, page: $page, order: $order, name: $letter, network: $network){
      paginationInfo {
        totalCount
      }
      collection {
        id
        uuid
        name
        short_name
        logo {
          url
        }
        network {
          id
          uuid
          name
        }
      }
    }
  }
`
