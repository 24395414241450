

import {defineComponent, PropType} from "vue";
import PerformerEntity from "@/entities/PerformerEntity";
import BIcon from "@/components/base/BIcon.vue";

export default defineComponent({
  components: {BIcon},
  props: {
    performer: {
      required: true,
      type: Object as PropType<PerformerEntity>
    }
  }
})
