
import {isNull, omitBy, toInteger, toString} from "lodash";
import {defineComponent} from "vue";
import {Performer, PerformerConnection, QueryPerformersArgs} from "@/types/graphql";
import {IResourceMeta} from "@/types/pagination";
import PerformerEntity from "@/entities/PerformerEntity";
import PerformersQuery from "@/queries/performer/PerformersQuery";
import BOverlay from "@/components/base/BOverlay.vue";
import BPopTransition from "@/components/base/BPopTransition.vue";
import PerformerCard from "@/components/performers/PerformerCard.vue";
import BPagination from "@/components/base/BPagination.vue";

export default defineComponent({
  components: {BPagination, PerformerCard, BPopTransition, BOverlay},
  apollo: {
    performers: {
      query: PerformersQuery,
      result() {
        this.$router.push({
          path: this.$route.path,
          query: omitBy({
            page: this.page,
            sortBy: this.sort.column,
            order: this.sort.order,
            query: this.query,
            letter: this.selectedLetter
          }, isNull)
        })
      },
      variables(): QueryPerformersArgs {
        return {
          // query: this.query,
          name: this.selectedLetter || undefined,
          itemsPerPage: 16,
          page: this.page,
          order: [
            {
              [this.sort.column]: this.sort.order
            }
          ]
        }
      }
    }
  },
  data() {
    return {
      selectedLetter: null as null | string,
      query: this.$route.query?.q ?? null,
      performers: {
        paginationInfo: {
          totalCount: 0,
          itemsPerPage: 16,
        }
      } as PerformerConnection,
      page: toInteger(this.$route.query?.page || '1'),
      sort: {
        column: this.$route.query.sortBy || 'name',
        order: this.$route.query.order || 'ASC'
      } as {
        column: string,
        order: 'ASC' | 'DESC'
      },
      letters: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      selectOptions: [
        {label: 'A-Z', value: {column: 'name', order: 'ASC'}},
        {label: 'Z-A', value: {column: 'name', order: 'DESC'}},
        {label: 'Newly Added', value: {column: 'created_at', order: 'DESC'}},
        {label: 'Older Added', value: {column: 'created_at', order: 'ASC'}},
        {label: 'Newly Updated', value: {column: 'updated_at', order: 'DESC'}},
        {label: 'Older Updated', value: {column: 'updated_at', order: 'ASC'}},
      ]
    }
  },
  mounted() {
    if (this.$route.query?.letter) {
      this.selectedLetter = toString(this.$route.query?.letter) as string
    }
  },
  computed: {
    performerEntities(): Array<PerformerEntity> {
      if (!this.performers) {
        return []
      }

      return (this.performers?.collection ?? []).map((performer: Performer) => {
        return new PerformerEntity(performer)
      })
    },
    paginationMeta(): IResourceMeta {
      return {
        current_page: this.page,
        per_page: this.performers?.paginationInfo?.itemsPerPage ?? 16,
        total: this.performers?.paginationInfo?.totalCount ?? 0
      }
    }
  },
  methods: {
    setLetter(letter: string): void {
      if (letter === this.selectedLetter) {
        this.selectedLetter = null
        return
      }

      this.selectedLetter = letter
    },
    onPaginate(page: number): void {
      this.page = page
    }
  }
})
