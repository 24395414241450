

import {defineComponent, PropType} from "vue";
import PerformerEntity from "@/entities/PerformerEntity";
import BImage from "@/components/base/BImage.vue";
import PerformerIconBar from "@/components/performers/PerformerIconBar.vue";

export default defineComponent({
  components: {PerformerIconBar, BImage},
  props: {
    performer: {
      required: true,
      type: Object as PropType<PerformerEntity>
    }
  }
})
