
import {defineComponent} from "vue";
import SiteQuery from "@/queries/sites/SiteQuery";
import {QuerySiteArgs, Site} from "@/types/graphql";
import {ApolloQueryResult} from "@apollo/client";
import BOverlay from "@/components/base/BOverlay.vue";
import BImage from "@/components/base/BImage.vue";
import SiteSceneList from "@/components/sites/SiteSceneList.vue";

export default defineComponent({
  components: {SiteSceneList, BImage, BOverlay},
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  apollo: {
    site: {
      query: SiteQuery,
      result(context: ApolloQueryResult<{ site: Site }>): void {
        if (!context.data || context.data?.site === null) {
          this.$router.push('/404')
        }
      },
      variables(): QuerySiteArgs {
        return {
          id: '/sites/' + this.slug
        }
      },
    }
  }
})
