import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "overflow-hidden mb-8 w-full bg-white rounded shadow" }
const _hoisted_2 = { class: "p-4" }
const _hoisted_3 = {
  key: 0,
  class: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_scene_card = _resolveComponent("scene-card")!
  const _component_b_pagination = _resolveComponent("b-pagination")!
  const _component_b_overlay = _resolveComponent("b-overlay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_overlay, {
      active: _ctx.$apollo.loading
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.scenes)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scenes.collection, (scene) => {
                  return (_openBlock(), _createBlock(_component_scene_card, {
                    key: `scene-${scene.id}`,
                    scene: scene
                  }, null, 8, ["scene"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_b_pagination, {
          meta: _ctx.paginationMeta,
          onChange: _ctx.setPage
        }, null, 8, ["meta", "onChange"])
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}