import {isNull, random, take} from "lodash";
import PerformerEntity from "@/entities/PerformerEntity";
import {luxon} from "@/plugins/luxon";
import {Maybe, Media, Scene, Site, Tag} from "@/types/graphql";

export default class SceneEntity {
  public createdAt?: string | null
  public date?: string | null
  public description?: string | null
  public externalId?: string | null
  public id?: string | null
  public image?: string | null
  public media?: Maybe<Media> | undefined
  public performerSites: PerformerEntity[]
  public site?: Maybe<Site> | undefined
  public slug?: string | null
  public tags?: Maybe<Array<Tag>> | undefined
  public title?: string | null
  public trailer?: string | null
  public updatedAt?: string | null
  public url?: string | null
  public uuid?: string | null

  constructor(scene: Scene) {
    this.createdAt = scene.created_at
    this.date = scene.date
    this.description = scene.description
    this.externalId = scene.external_id
    this.id = scene.id
    this.image = scene.image
    this.media = scene.media
    this.performerSites = []

    if (scene.performerSites?.collection) {
      this.performerSites = scene.performerSites.collection
        .filter((performer) => !isNull(performer))
        .map((performerSite): PerformerEntity => {
          return new PerformerEntity(performerSite?.performer || undefined, performerSite ?? undefined)
        })
    }

    this.site = scene.site
    this.slug = scene.slug
    // this.tags = scene.tags
    this.tags = []
    this.title = scene.title
    this.trailer = scene.trailer
    this.updatedAt = scene.updated_at
    this.url = scene.url
    this.uuid = scene.uuid
  }

  get background(): string {
    if (this.media) {
      return this.media.url ?? this.placeholder
    }

    return this.placeholder
  }

  get placeholder(): string {
    const images = [
      'https://cdn.metadataapi.net/default1.png',
      'https://cdn.metadataapi.net/default2.png',
      'https://cdn.metadataapi.net/default3.png',
    ]

    return images[random(0, 2)]
  }

  get dateFormatted(): string | null {
    if (!this.date) {
      return null
    }

    return luxon.fromISO(this.date).toLocaleString(luxon.DATE_FULL);
  }

  get siteIcon(): string | null {
    if (this.site && this.site.favicon) {
      return this.site?.favicon.url ?? null
    }

    return null
  }

  get limitedPerformers(): PerformerEntity[] {
    return take<PerformerEntity>(this.performers, 3)
  }

  get performers(): PerformerEntity[] {
    if (!this.performerSites?.length) {
      return []
    }

    return this.performerSites
  }

}
