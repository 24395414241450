import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "group" }
const _hoisted_2 = { class: "flex p-2 bg-gray-100 group-hover:bg-gray-200" }
const _hoisted_3 = { class: "px-3 font-bold text-white bg-gray-600 group-hover:bg-pink-400 rounded" }
const _hoisted_4 = { class: "flex-grow text-right select-all" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.value === null ? 'N/A' : _ctx.value), 1)
    ])
  ]))
}