

import {defineComponent} from "vue";
import {Network, QueryNetworkArgs, QuerySitesArgs, SiteConnection} from "@/types/graphql";
import NetworkQuery from "@/queries/network/NetworkQuery";
import {ApolloQueryResult} from "@apollo/client";
import SitesQuery from "@/queries/sites/SitesQuery";
import {toString} from "lodash";
import BOverlay from "@/components/base/BOverlay.vue";
import BImage from "@/components/base/BImage.vue";

export default defineComponent({
  components: {BImage, BOverlay},
  apollo: {
    network: {
      query: NetworkQuery,
      result(context: ApolloQueryResult<{network: Network}>) {
        if (!context.data || context.data.network === null) {
          // this.$router.push('/404')
        }
      },
      variables(): QueryNetworkArgs {
        return {
          id: this.slug
        }
      },
    },
    sites: {
      query: SitesQuery,
      variables(): QuerySitesArgs {
        return {
          network: this.network?._id ? toString(this.network?._id) : undefined
        }
      },
      skip(): boolean {
        return !this.network?._id
      }
    }
  },
  props: {
    slug: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      network: {} as Network,
      sites: {} as SiteConnection,
    }
  }
})
