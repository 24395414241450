import {defineStore} from 'pinia'

// main is the name of the store. It is unique across your application
// and will appear in devtools
export const useSettingsStore = defineStore('settings', {
  state: () => {
    return {
      sfwMode: false
    }
  },
  actions: {
    toggleSfwMode () {
      this.sfwMode = !this.sfwMode
    }
  }
});
