
import {defineComponent, PropType} from "vue";
import {IResourceMeta} from "@/types/pagination";

export default defineComponent({
  name: 'BPagination',
  props: {
    limit: {
      type: Number,
      required: false,
      default: 6
    },
    meta: {
      type: Object as PropType<IResourceMeta>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedTotal(): string {
      return new Intl.NumberFormat().format(this.meta.total)
    },

    ranges(): number {
      return Math.ceil(this.limit / 2)
    },

    pagerFrom(): string {
      return new Intl.NumberFormat().format((this.meta.current_page - 1) * this.meta.per_page)
    },

    pagerTo(): string {
      return new Intl.NumberFormat().format(this.meta.current_page * this.meta.per_page)
    },

    currentPage(): number {
      return Math.min(0, (this.meta.current_page - 1) * this.meta.per_page)
    },

    totalPages(): number {
      return Math.ceil( this.meta.total / this.meta.per_page)
    }
  },
  emits: ['change'],
  methods: {
    setPage(page = 1): void {
      this.$emit('change', page)
    }
  }
})
