
import {Scene} from "@/types/graphql";
import {defineComponent} from "vue";
import SceneEntity from "@/entities/SceneEntity";
import SceneQuery from "@/queries/scene/SceneQuery";
import {ApolloQueryResult} from "@apollo/client";
import BImage from "@/components/base/BImage.vue";
import PerformerCard from "@/components/performers/PerformerCard.vue";

export default defineComponent({
  components: {PerformerCard, BImage},
  apollo: {
    scene: {
      query: SceneQuery,
      variables() {
        return {
          id: '/scenes/' + this.$route.params.slug
        }
      },
      // errorPolicy: 'all',
      result({data}: ApolloQueryResult<Scene>) {
        console.log(data)
      }
    }
  },
  data() {
    return {
      scene: null
    }
  },
  computed: {
    sceneEntity(): SceneEntity | null {
      if (!this.scene) {
        return null
      }
      return new SceneEntity(this.scene)
    }
  }
})

