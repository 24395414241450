import gql from "graphql-tag";

export default gql`
  query SingleNetwork($id: ID!) {
    network(id: $id) {
      id
      uuid
      name
      short_name
      favicon {
        url
      }
      logo {
        url
      }
    }
  }
`
