


import {defineComponent, PropType} from "vue";
import ScenesQuery from "@/queries/scene/ScenesQuery";
import {QueryScenesArgs, SceneConnection, Site} from "@/types/graphql";
import {IResourceMeta} from "@/types/pagination";
import BOverlay from "@/components/base/BOverlay.vue";
import BPagination from "@/components/base/BPagination.vue";
import SceneCard from "@/components/scenes/SceneCard.vue";

export default defineComponent({
  components: {SceneCard, BPagination, BOverlay},
  apollo: {
    scenes: {
      query: ScenesQuery,
      result(): void {
        this.$router.push({
          path: this.$route.path,
          query: {
            page: this.page,
          }
        })
      },
      variables(): QueryScenesArgs {
        return {
          page: this.page,
          itemsPerPage: 8,
          site: this.site._id.toString()
        }
      }
    }
  },
  props: {
    site: {
      type: Object as PropType<Site>,
      required: true
    }
  },
  data() {
    return {
      page: this.$route.query.page ? parseInt(this.$route.query.page as string) : 1,
      scenes: {
        collection: [],
        paginationInfo: {
          itemsPerPage: 8,
          page: this.page,
          totalCount: 0,
          lastPage: 0
        }
      } as SceneConnection
    }
  },
  methods: {
    setPage(page: number): void {
      this.page = page
    }
  },
  computed: {
    paginationMeta(): IResourceMeta {
      return {
        current_page: this.page,
        per_page: 8,
        total: this.scenes.paginationInfo.totalCount
      }
    }
  }
})

