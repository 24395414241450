


import {defineComponent} from "vue";
import {Performer, QueryPerformerArgs} from "@/types/graphql";
import PerformerEntity from "@/entities/PerformerEntity";
import {ApolloQueryResult} from "@apollo/client";
import PerformerQuery from '@/queries/performer/PerformerQuery'
import BOverlay from "@/components/base/BOverlay.vue";
import BImage from "@/components/base/BImage.vue";
import BIcon from "@/components/base/BIcon.vue";
import PerformerProperty from "@/components/performers/PerformerProperty.vue";
import PerformerAlias from "@/components/performers/PerformerAlias.vue";
import PerformerSceneList from "@/components/performers/PerformerSceneList.vue";

export default defineComponent({
  components: {PerformerSceneList, PerformerAlias, PerformerProperty, BIcon, BImage, BOverlay},
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  apollo: {
    performer: {
      query: PerformerQuery,
      result(context: ApolloQueryResult<{performer: Performer}>) {
        if (!context.data.performer) {
          this.$router.push('/404')
        }
      },
      variables(): QueryPerformerArgs {
        return {
          id: '/performers/' + this.slug
        }
      }
    }
  },
  data() {
    return {
      performer: null as Performer | null
    }
  },
  computed: {
    _performer(): PerformerEntity | null {
      if (!this.performer) {
        return null
      }

      return new PerformerEntity(this.performer)
    }
  },
})
