import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "group rounded overflow-hidden flex-col bg-white shadow" }
const _hoisted_2 = { class: "overflow-hidden relative" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "p-3 text-xl truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_image = _resolveComponent("b-image")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_performer_icon_bar = _resolveComponent("performer-icon-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: _ctx.performer.url
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "bg-cover bg-center",
            style: _normalizeStyle(`background-image: url('${_ctx.performer.defaultPoster}');`)
          }, [
            _createVNode(_component_b_image, {
              src: _ctx.performer.poster,
              class: "transform h-64 w-full object-cover transition ease-in-out duration-300 group-hover:scale-105",
              alt: _ctx.performer.name,
              preset: "posterSmall"
            }, null, 8, ["src", "alt"])
          ], 4)
        ]),
        _: 1
      }, 8, ["to"]),
      _createVNode(_component_performer_icon_bar, { performer: _ctx.performer }, null, 8, ["performer"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h2", _hoisted_4, [
        _createVNode(_component_router_link, {
          to: _ctx.performer.url,
          title: _ctx.performer.name
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.performer.name), 1)
          ]),
          _: 1
        }, 8, ["to", "title"])
      ])
    ])
  ]))
}