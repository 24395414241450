import gql from "graphql-tag";

export default gql`query SingleSite($id: ID!) {
  site(id: $id) {
    _id
    id
    uuid
    url
    name
    short_name
    description
    favicon {
      url
    }
    logo {
      url
    }
    network {
      id
      name
      short_name
      logo {
        url
      }
    }
  }
}`
