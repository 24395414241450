import {kebabCase} from "lodash";
import {luxon} from "@/plugins/luxon";
import {Media, Performer, PerformerAlias, PerformerSite} from "@/types/graphql";

export default class PerformerEntity {
  private performerSite: PerformerSite | undefined;
  private performer: Performer | undefined;

  active: boolean | null
  aliases: PerformerAlias[] | null
  astrology: string | null
  bio: string | null
  birthplace: string | null
  birthplaceCode: string | null
  createdAt: string | null
  cupsize: string | null
  ethnicity: string | null
  hairColour: string | null
  height: string | null
  measurements: string | null
  performerSites: PerformerSite[] | null
  piercings: string | null
  slug: string | null
  startedAt: string | null
  tattoos: string | null
  updatedAt: string | null
  uuid: string | null
  weight: string | null

  constructor(performer?: Performer, performerSite?: PerformerSite) {
    this.performer = performer;
    this.performerSite = performerSite;

    this.active = performer?.active ?? false
    this.aliases = performer?.aliases?.collection ?? null
    this.astrology = performer?.astrology ?? null
    this.bio = performer?.bio ?? null
    this.birthplace = performer?.birthplace ?? null
    this.birthplaceCode = performer?.birthplaceCode ?? null
    this.createdAt = performer?.createdAt ?? null
    this.cupsize = performer?.cupsize ?? null
    this.ethnicity = performer?.ethnicity ?? null
    this.hairColour = performer?.hairColour ?? null
    this.height = performer?.height ?? null
    this.measurements = performer?.measurements ?? null
    this.performerSites = performer?.performerSites?.collection ?? null
    this.piercings = performer?.piercings ?? null
    this.slug = performer?.slug ?? null
    this.startedAt = performer?.startedAt ?? null
    this.tattoos = performer?.tattoos ?? null
    this.updatedAt = performer?.updatedAt ?? null
    this.uuid = performer?.uuid ?? null
    this.weight = performer?.weight ?? null

    if (!performer && performerSite?.performer) {
      this.performer = performerSite?.performer
    }
  }

  get id(): string | number {
    if (this.performerSite) {
      return this.performerSite.id || this.performerSite.uuid
    }

    return this.performer?.id ?? this.performer?.uuid ?? this.performer?.slug ?? ''
  }

  get posters(): Media[] {
    return this.performer?.posters ?? []
  }

  get poster(): string {
    if (!this.performer) {
      return this.defaultPoster
    }

    if (!this.performer.posters?.length) {
      return this.defaultPoster
    }

    return this.performer.posters[0]?.url ?? this.defaultPoster
  }

  get defaultPoster(): string {
    switch (this.gender) {
      case 'Male':
        return 'https://cdn.metadataapi.net/male.png';
      case 'Female':
      default:
        return 'https://cdn.metadataapi.net/female.png';
    }
  }

  get gender(): string {
    return this.performerSite?.gender || this.performer?.gender || 'Unknown'
  }

  get nationality(): string | null {
    return this.performer?.nationality || null
  }

  get birthday(): string | null {
    return this.performer?.birthday || null
  }

  get birthdate(): string | null {
    if (!this.birthday) {
      return null
    }

    return luxon.fromISO(this.birthday).toLocaleString()
  }

  get age(): string | null {
    if (!this.birthday) {
      return null
    }

    return luxon.fromISO(this.birthday).toRelative({style: 'narrow'})
  }

  get genderIcon(): string {
    switch (this.gender) {
      case 'Male':
        return 'bi:gender-male';
      case 'Female':
        return 'bi:gender-female';
      default:
        return 'bi:gender-ambiguous';
    }
  }

  get flagIcon(): string | null {
    if (!this.nationality) {
      return null
    }

    return 'emojione:flag-for-' + kebabCase(this.nationality)
  }

  get url(): string {
    if (this.performer) {
      return `/performers/${this.performer.slug}`
    }

    return '#'
  }

  get name(): string {
    if (this.performerSite) {
      return this.performerSite.name
    }

    return this.performer?.name ?? ''
  }
}
