import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "p-4 my-3 w-full flex justify-center btn-group pt-6 flex-wrap space-x-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "h-32 rounded-md shadow bg-gray-700 text-white p-3 flex items-center justify-center" }
const _hoisted_4 = ["src", "alt", "title"]
const _hoisted_5 = {
  key: 1,
  class: "text-2xl break-words text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_button = _resolveComponent("n-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_b_pop_transition = _resolveComponent("b-pop-transition")!
  const _component_b_pagination = _resolveComponent("b-pagination")!
  const _component_b_overlay = _resolveComponent("b-overlay")!

  return (_openBlock(), _createBlock(_component_b_overlay, {
    show: _ctx.$apollo.loading,
    class: "h-full"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.letters, (letter) => {
          return (_openBlock(), _createBlock(_component_n_button, {
            key: `letter-${letter}`,
            type: "info",
            ghost: _ctx.selectedLetter !== letter,
            class: "w-12",
            onClick: ($event: any) => (_ctx.setLetter(letter))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(letter), 1)
            ]),
            _: 2
          }, 1032, ["ghost", "onClick"]))
        }), 128))
      ]),
      (_ctx.networks)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_b_pop_transition, { class: "p-4 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-3" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.networks.collection, (network) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    key: network.id,
                    to: `/networks/${network.short_name}`
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        (network.logo)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: network.logo.url,
                              alt: network.name,
                              title: network.name,
                              class: "max-h-24 object-contain"
                            }, null, 8, _hoisted_4))
                          : (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(network.name), 1))
                      ])
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                }), 128))
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_b_pagination, {
        meta: _ctx.paginationMeta,
        onChange: _ctx.onPaginate
      }, null, 8, ["meta", "onChange"])
    ]),
    _: 1
  }, 8, ["show"]))
}