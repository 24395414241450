
import {defineComponent, PropType} from "vue";
import {Scene} from "@/types/graphql";
import SceneEntity from "@/entities/SceneEntity";
import BImage from "@/components/base/BImage.vue";
import PerformerBadge from "@/components/performers/PerformerBadge.vue";

export default defineComponent({
  components: {PerformerBadge, BImage},
  props: {
    scene: {
      type: Object as PropType<Scene>,
      required: true,
    },
  },
  computed: {
    _scene() {
      return new SceneEntity(this.scene)
    }
  }
})
