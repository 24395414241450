
import {defineComponent, PropType} from "vue";
import {PerformerSite} from "@/types/graphql";
import PerformerEntity from "@/entities/PerformerEntity";

export default defineComponent({
  props: {
    performerSite: {
      type: Object as PropType<PerformerSite>,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    badgeClasses(): string {
      let colour = 'bg-gray-200';

      switch (this._performerSite.gender) {
        case 'Male':
          colour = this.dark ? 'bg-blue-600' : 'bg-blue-200'
          break;
        case 'Female':
          colour = this.dark ? 'bg-pink-600' : 'bg-pink-200'
          break;
        case 'Trans':
          colour = this.dark ? 'bg-purple-600' : 'bg-purple-200'
          break;
      }

      const text = this.dark ? 'text-white' : 'text-gray-600'

      return [
        'py-1',
        'px-2',
        'mr-2',
        'mb-1',
        'text-xs',
        'rounded',
        colour,
        text,
      ].join(' ')
    },
    _performerSite() {
      return new PerformerEntity(this.performerSite?.performer ?? undefined, this.performerSite);
    },
  },
  methods: {}
})
