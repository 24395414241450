import gql from "graphql-tag";

export default gql`
	query MoviesQuery($itemsPerPage: Int, $page: Int, $order: [MovieFilter_order], $site: String) {
		movies(itemsPerPage: $itemsPerPage, page: $page, order: $order, site: $site) {
			paginationInfo {
				totalCount
			}
			collection {
          id
          uuid
          title
          media {
              uuid
              url
          }
			}
		}
	}
`
