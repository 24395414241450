import gql from "graphql-tag";

export default gql`
  query Scene($id: ID!) {
    scene(id: $id) {
      id
      media {
        uuid
        url
      }
      trailer
      title
      date
      slug
      external_id
      description
      created_at
      performerSites {
        collection {
          id
          name
          performer {
            id
            name
            gender
            slug
            posters {
              uuid
              url
            }
          }
        }
      }
      site {
        id
        name
        short_name
        favicon {
          uuid
          url
        }
      }
      tags {
        collection {
          id
          name
        }
      }
    }
  }
`
