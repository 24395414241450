
import {defineComponent, PropType} from "vue";
import {PerformerSite} from "@/types/graphql";

export default defineComponent({
  props: {
    performer: {
      type: Object as PropType<PerformerSite>,
      required: true
    }
  }
})
