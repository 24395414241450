
import { defineComponent, PropType } from 'vue'
import { isString } from 'lodash'
import { Icon } from '@iconify/vue'

export default defineComponent({
  name: 'BIcon',
  components: { Icon },
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    icon: {
      required: true,
      type: [
        String,
        Array
      ] as PropType<string | Record<string, string>>
    }
  },
  computed: {
    iconKey () {
      return `${this.actualIcon}-${this.inline}`
    },
    actualIcon (): string {
      if (isString(this.icon)) {
        return this.icon
      }

      switch (this.icon[0]) {
        case 'fas':
          return `fa-solid:${this.icon[1]}`
        case 'mdi':
          return this.icon[1].replace('mdi-', 'mdi:')
        default:
          return ''
      }
    }
  }
})
