
import {defineComponent} from 'vue'
import SceneQuery from '../queries/scene/ScenesQuery'
import SceneCard from "@/components/scenes/SceneCard.vue";
import FeaturedScene from "@/components/scenes/FeaturedScene.vue";
import {SceneConnection} from "@/types/graphql";
import BOverlay from "@/components/base/BOverlay.vue";

export default defineComponent({
  components: {BOverlay, FeaturedScene, SceneCard},
  apollo: {
    scenes: {
      query: SceneQuery,
      variables() {
        return {
          itemsPerPage: 8,
          page: 1,
          order: [{created_at: 'desc'}]
        }
      }
    }
  },
  data() {
    return {
      scenes: {} as SceneConnection
    }
  }
})
