import gql from "graphql-tag";

export default gql`
  query SinglePerformer($id: ID!) {
    performer(id: $id) {
      id
      name
      slug
      created_at
      birthday
      birthplace
      posters {
        uuid
        url
      }
      gender
      uuid
      piercings
      tattoos
      bio
      measurements
      weight
      height
      hair_colour
      cupsize
      ethnicity
      nationality
      astrology

      performerSites {
        collection {
          id
          uuid
          name
          network {
            name
            short_name
            favicon {
              uuid
              url
            }
          }
        }
      }
    }
  }
`
