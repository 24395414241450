import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {createPinia} from 'pinia'
import Apollo from './plugins/apollo'
import './assets/tailwind.css'
import NaiveuiPlugin from './plugins/naiveui'

createApp(App)
  .use(router)
  .use(NaiveuiPlugin)
  .use(Apollo)
  .use(createPinia())
  .mount('#app')

