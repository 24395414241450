import gql from "graphql-tag";

export default gql`
  query ($limit: Int, $page: Int, $name: String, $order: [NetworkFilter_order]) {
    networks(itemsPerPage: $limit, page: $page, name: $name, order: $order){
      paginationInfo {
        totalCount
      }
      collection {
        id
        uuid
        name
        short_name
        logo {
          url
        }
      }
    }
  }
`
